<template>
    <AkielWorld/>
</template>

<script>
// @ is an alias to /src
import AkielWorld from '@/components/AkielWorld.vue'

export default {
  name: 'Home',
  components: {
    AkielWorld
  }
}
</script>
